// Google Analytics helpers
export const gaPageview = (url) => {
  if (!process.env.REACT_APP_GOOGLE_ANALYTICS) return;

  window.gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS, {
    page_path: url,
  })
}

export const gaEvent = ({ action, params }) => {
  if (!process.env.REACT_APP_GOOGLE_ANALYTICS) return;

  window.gtag('event', action, params)
}
